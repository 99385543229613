import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./Users.css";

// For the material modal
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DataGrid } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";

// The MUI alert definition
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// The style for the modals
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  bgcolor: "background.paper",
  border: "2px solid lightBlue",
  borderRadius: 6,
  boxShadow: 24,
  p: 4,
};

// The grid columns
const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "username", headerName: "Username", width: 160 },
  { field: "name", headerName: "Name", width: 160 },
  { field: "surname", headerName: "Surname", width: 160 },
  { field: "email", headerName: "email", width: 200 },
  { field: "telephone", headerName: "Telephone", width: 150 },
  // { field: "taxnumber", headerName: "Tax Number", width: 100 },
];

// Displaying the Users to the admin and their information
function Users() {
  let navigate = useNavigate();

  // The three required lists so that it can be updated dynamically
  const [userList, setUserList] = useState([]);
  const [notApprovedList, setNotApprovedList] = useState([]);
  const [approvedList, setApprovedList] = useState([]);

  useEffect(() => {
    const head = {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
      },
    };

    axios.get("http://localhost:33123/auth", head).then((res) => {
      setUserList(res.data);
    });

    axios.get("http://localhost:33123/auth/approve", head).then((res) => {
      setNotApprovedList(res.data);
    });
  }, [approvedList]);

  const handleApprove = () => {
    if (approvedList.length !== 0) {
      const head = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      // Send the approve in the backend
      axios
        .put("http://localhost:33123/auth/approve", approvedList, head)
        .then((res) => {
          if (res.data.error) {
            setErrorMessage(res.data.error);
            handleClickAlertError();
          } else {
            handleClickAlert();
          }
        });

      // Make the changes here as well
      setApprovedList([]);
      setUserList([]);
    }
    setOpen(false);
  };

  // Pagination Information
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 9;
  const visitedPages = pageNumber * usersPerPage;
  const pageCount = Math.ceil(userList.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Displaying the users of this particular page in the pagination
  const displayUsers = userList
    .slice(visitedPages, visitedPages + usersPerPage)
    .map((value, key) => {
      return (
        <div class="adminusercard">
          <div class="adminuserinfos">
            <div class="adminuserimage"></div>
            <div class="adminuserinfo">
              <div>
                <p class="adminusername">
                  {" "}
                  {value.approved ? (
                    <CheckCircleIcon style={{ color: "teal" }} />
                  ) : (
                    <NotInterestedIcon style={{ color: "teal" }} />
                  )}{" "}
                  {value.username}
                </p>

                <p class="adminuserfunction">
                  {value.location}, {value.country}
                </p>
              </div>
            </div>
          </div>
          <button
            class="adminuserrequest"
            type="button"
            onClick={() => {
              navigate(`/profile/${value.id}`);
            }}
          >
            See More
          </button>
        </div>
      );
    });

  // For the alerts
  const [openAlert, setOpenAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const handleClickAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlertR = (event, reason) => {
    setOpenAlert(false);
    window.location.reload();
  };

  // For the error alerts
  const [openAlertError, setOpenAlertError] = React.useState(false);
  const handleClickAlertError = () => {
    setOpenAlertError(true);
  };
  const handleCloseAlertError = (event, reason) => {
    setOpenAlertError(false);
  };

  // These are for the Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="container">
        <div className="User-T">
          <header className="title">Users</header>
        </div>
      </div>
      <div className="container">
        <header className="header-text">
          Click on a user profile for more details
        </header>
      </div>

      <div className="container">
        <button className="custom-button" onClick={handleOpen}>
          Check Applications
        </button>
        <div className="userscontainer">{displayUsers}</div>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationButtons"}
          previousLinkClassName={"previousButton"}
          nextLinkClassName={"nextButton"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="custom-modal">
            <div className="header">
              <headerito>Users Awaiting Confirmation</headerito>
            </div>
            <div style={{ height: 369, width: 1100 }}>
              <DataGrid
                className="custom-data-grid"
                rows={notApprovedList}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(data) => {
                  setApprovedList(data);
                }}
              />
            </div>
            <button className="custom-button" onClick={handleApprove}>
              Approve Selected
            </button>
          </Box>
        </Modal>

        {/* Message alert */}
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlertR}
        >
          <Alert
            onClose={handleCloseAlertR}
            severity="success"
            className="custom-alert"
          >
            Users successfully approved!
          </Alert>
        </Snackbar>

        {/* Error message alert */}
        <Snackbar
          open={openAlertError}
          autoHideDuration={6000}
          onClose={handleCloseAlertError}
        >
          <Alert
            onClose={handleCloseAlertError}
            severity="warning"
            className="custom-alert"
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default Users;
