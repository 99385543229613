import React from "react";
import "./Card02.css";
function card02() {
  return (
    <div className="cdr">
      <div class="containerk">
        <div class="cardr">
          <div class="front-content">
            <p>About Us</p>
          </div>
          <div class="content">
            <h3>About</h3>
            <p>
              King Of Asia has a fine collection. With having knowledge of the
              industry together with proud family traditions and respect and
              almost notably passion has passed through generations. We assure
              our customers receive the wide selection of high quality Museum
              Gems and excellent service located at pioneer Museum GemS trading
              place in, Sri Lanka.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default card02;
