import React, { useState } from "react";
import "./About.scss";

function About() {
  const [expanded, setExpanded] = useState(false);

  const handleReadMoreClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="containeruy">
      <div className="container-inner">
        <div className="main-content">
          <div className="main-headings" id="main-headings-js">
            <div className="heading">
              <h3 id="heading1">Welcome to</h3>
              <br />
              <h1 id="heading3">King of Asia</h1>
            </div>
            <br />
            <div className="short-bio">
              <p>
                Our Company is dedicated to providing the highest level of
                service that you require, considering the most appropriate for
                your needs. We are here to expand and meet new more or buyers
                who require their Museum Gems in the best quality they need.
              </p>
            </div>
          </div>

          <div
            className={`more-info ${expanded ? "expanded" : ""}`}
            id="more-info-js"
          >
            <br />
            <br />

            <h4>About Us </h4>
            <p>
              King Of Asia has a fine collection. With having knowledge of the
              industry together with proud family traditions and respect and
              almost notably passion has passed through generations. We assure
              our customers receive the wide selection of high quality Museum
              Gems and excellent service located at pioneer Museum GemS trading
              place in, Sri Lanka.
            </p>
          </div>
        </div>

        <div className="image-wrapper">
          <div className="image-container">
            <img
              className="image"
              src="/images/diamond.jpg"
              alt="image of a young woman."
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
