import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import Logout from "../pages/Logout";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import "./Navbar.css";

function Navbar(props) {
  const { authState } = useContext(AuthContext);
  const [gotMail, setGotMail] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    axios
      .get(`http://localhost:33123/mail/newmailcount/${authState.id}`)
      .then((respi) => {
        if (respi.data.count === 0) {
          setGotMail(0);
        } else {
          setGotMail(respi.data.count);
        }
        console.log(respi.data.count);
      })
      .catch((error) => {});
  }, [authState.id]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
     <div>
      <div id="header">
        <div className="logo">
          <a href="#">King Of Asia</a>
        </div>
        
        
        <div id="menu-icon" onClick={toggleMenu}>
          <span className={`first ${menuOpen ? 'active' : ''}`}></span>
          <span className={`second ${menuOpen ? 'active' : ''}`}></span>
          <span className={`third ${menuOpen ? 'active' : ''}`}></span>
        </div>
        <nav style={{ display: menuOpen ? 'block' : 'none' }}>
          <ul>
            {!authState.status ? (
              <div>
                <li>
                  <a
                    href="/"
                    className={props.clicked === "home" ? "selected" : ""}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="/login"
                    className={props.clicked === "login" ? "selected" : ""}
                  >
                    Login/Register
                  </a>
                </li>
                <li>
                  <a
                    href="/auctions"
                    className={props.clicked === "auctions" ? "selected" : ""}
                  >
                    {" "}
                    Auctions
                  </a>
                </li>
                
              </div>
            ) : (
              <div>
                {authState.username === "admin" ? (
                  <div>
                    
                    <li>
                      <a
                        href="/users"
                        className={props.clicked === "users" ? "selected" : ""}
                      >
                        {" "}
                        Users
                      </a>
                    </li>

                    <li>
                      <a
                        href="/addcategory"
                        className={
                          props.clicked === "addcategory" ? "selected" : ""
                        }
                      >
                        {" "}
                        Categories
                      </a>
                    </li>
                    <li>
                      <a
                        href="/auctions"
                        className={
                          props.clicked === "auctions" ? "selected" : ""
                        }
                      >
                        {" "}
                        Auctions
                      </a>
                    </li>
                    <li>
                      <a
                        href="/export"
                        className={props.clicked === "export" ? "selected" : ""}
                      >
                        {" "}
                        Export
                      </a>
                    </li>
                    <li>
                        <a>
                          <Logout />
                        </a>
                      </li>
                  </div>
                ) : (
                  <div>
                    <ul>
                      <li>
                        <a
                          href="/mail"
                          className={props.clicked === "mail" ? "selected" : ""}
                        >
                          {" "}
                          Your Mails{" "}
                        </a>
                      </li>

                      <li>
                        <a
                          href="/createitem"
                          className={
                            props.clicked === "createitem" ? "selected" : ""
                          }
                        >
                          {" "}
                          Create A Listing
                        </a>
                      </li>

                      <li>
                        <a
                          href="/auctions"
                          className={
                            props.clicked === "auctions" ? "selected" : ""
                          }
                        >
                          {" "}
                          Auctions
                        </a>
                      </li>
                      <li>
                        <a
                          href="/search"
                          className={
                            props.clicked === "search" ? "selected" : ""
                          }
                        >
                          {" "}
                          Search
                        </a>
                      </li>

                      <li>
                        <a
                          href="/profile/:id"
                          className={
                            props.clicked === "search" ? "selected" : ""
                          }
                        >
                          {""}
                          Sign in as {authState.username}
                        </a>
                      </li>
                      <li>
                        <a>
                          <Logout />
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}

            
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;

function NavLink({ to, text, selected }) {
  return (
    <Link
      to={to}
      style={{
        color: selected ? "Black" : "rgba(0, 0, 0, 0.36)",
        marginRight: "20px",
      }}
    >
      {text}
    </Link>
  );
}

function MailNavLink({ to, gotMail, selected }) {
  return (
    <Link to={to} style={{ color: "Black", marginTop: "6px" }}>
      {gotMail > 0 ? (
        <Badge badgeContent={gotMail} color="info">
          <MailIcon color="Black" />
        </Badge>
      ) : (
        <MailIcon color={selected ? "white" : "rgba(0, 0, 0, 0.36)"} />
      )}
    </Link>
  );
}
