import React from "react";
import "./Card.css";
function Card() {
  return (
    <div className="reloadcard">
      <div class="card work">
        <div class="img-section">
          
        </div>
        <div class="card-desc">
          <div class="card-header">
            <div class="card-title">Reload</div>
            <div class="card-menu">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
          <div class="card-time">Mobitel</div>
          <p class="recent">
            <a
              href="https://www.mobitel.lk/reload-and-bill-payment"
              target="_blank"
            >
              See More
            </a>
          </p>
        </div>
      </div>
      <div class="card work">
        <div class="img-section">
          
        </div>
        <div class="card-desc">
          <div class="card-header">
            <div class="card-title">Reload</div>
            <div class="card-menu">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
          <div class="card-time">Dialog</div>
          <p class="recent">
            <a
              href="https://www.dialog.lk/bill-payment-methods"
              target="_blank"
            >
              See More
            </a>
          </p>
        </div>
      </div>

      <div class="card work">
        <div class="img-section">
          
        </div>
        <div class="card-desc">
          <div class="card-header">
            <div class="card-title">Reload</div>
            <div class="card-menu">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
          <div class="card-time">Airtel</div>
          <p class="recent">
            <a href="https://recharge.airtel.lk/" target="_blank">
              See More
            </a>
          </p>
        </div>
      </div>

      <div class="card work">
        <div class="img-section">
          
        </div>
        <div class="card-desc">
          <div class="card-header">
            <div class="card-title">Reload</div>
            <div class="card-menu">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
          <div class="card-time">Hutch</div>
          <p class="recent">
            <a href="https://online.hutch.lk/hopp/" target="_blank">
              See More
            </a>
          </p>
        </div>
      </div>

      <div class="card work">
        <div class="img-section">
          
        </div>
        <div class="card-desc">
          <div class="card-header">
            <div class="card-title">Reload</div>
            <div class="card-menu">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
          <div class="card-time">SLT </div>
          <p class="recent">
            <a href="https://pay.slt.lk/web/" target="_blank">
              See More
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Card;
