import React from "react";
import "./BuyCard.css"; // Import your CSS file

function BuyCard({ kind }) {
  const imageStyle = {
    backgroundImage: `url(${kind.imageUrl})`,
    backgroundSize: "cover", // Adjust this property to control image sizing
    backgroundPosition: "center center", // Adjust this property for positioning
  };
  return (
    <div className="buycard">
      <div className="card-image">
        <img src={kind.imageUrl} alt="Product" />
      </div>
      <div className="card-content">
        <div className="card-title">{kind.title}</div>
        <div className="card-description">{kind.description}</div>
        <button className="card-button">Visit</button>
      </div>
    </div>
  );
}

export default BuyCard;
