import React from "react";
import { useNavigate } from "react-router-dom";
import BuyCard from "./BuyCard";
import "./Landing.css";
import { CssBaseline } from "@mui/material";
import Slider from "../Searching/Slider";
import Slider2 from "./Slider2";
import Card02 from "./Card02";
import Card03 from "./Card03";

import About from "./About";
import Card from "./Card";
import Card04 from "./Card04";
import Card05 from "./Card05";

function Landing() {
  let navigate = useNavigate();

  const goToAuctions = () => {
    navigate("/auctions");
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const kind = [
    {
      title: "Sell",
      description: "Selling with us is rather easy...",
      imageUrl: "http://localhost:33123/images/kobuR.jpg",
      time: 1500,
    },
    {
      title: "Buy",
      description: "In our auctions, items are bid on and sold...",
      imageUrl: "http://localhost:33123/images/saadR.jpg",
      time: 1500,
    },
  ];

  return (
    <div className="landing-container">
      <CssBaseline />
      <About />
      <br/>
      <div className="slider2fr">
        <Card02 />
        <Card03 />
      </div>
      <Slider />
      <br />
      <div className="loginadnregister">
        <Card04 />
        <Card05 />
      </div>
      <br />
      <br />
      <div className="slider2f">
        <Slider2 />
        <Slider2 />
        <Slider2 />
      </div>
      <Card />
      <div>
        <div className="banner-container"></div>
      </div>
    </div>
  );
}

export default Landing;
