import React from "react";
import "./Card04.css";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

function Card04() {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <div class="cardq">
      <p class="headingq"> Login or Register</p>
      <p class="paraq">
        Registering for this site allows you to access your order status and
        history. Just fill in the fields below, and we'll get a new account set
        up for you in no time. We will only ask you for information necessary to
        make the purchase process faster and easier.{" "}
      </p>
      <div class="overlayq"></div>
      <div className="clogin">
        <button className="cardq-btnq" onClick={goToLogin}>
          Login or Register
        </button>{" "}
      </div>
    </div>
  );
}

export default Card04;
