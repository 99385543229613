import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Slider.css";

const Slider = () => {
  const [slideData, setSlideData] = useState([
    {
      src: "https://images.unsplash.com/photo-1478728073286-db190d3d8ce6?ixlib=rb-0.3.5&s=87131a6b538ed72b25d9e0fc4bf8df5b&auto=format&fit=crop&w=1050&q=80",
      title: "Slide 1",
      copy: "Find Your Item With King Of Asia.",
    },
    {
      src: "https://images.unsplash.com/photo-1440557653082-e8e186733eeb?crop=entropy&fit=crop&fm=jpg&h=1325&ixjsv=2.1.0&ixlib=rb-0.3.5&q=80&w=2500",
      title: "Slide 2",
      copy: "Find Your Item With King Of Asia.",
    },
    {
      src: "https://images.unsplash.com/photo-1449057528837-7ca097b3520c?crop=entropy&fit=crop&fm=jpg&h=1325&ixjsv=2.1.0&ixlib=rb-0.3.5&q=80&w=2500",
      title: "Slide 3",
      copy: "Find Your Item With King Of Asia.",
    },
  ]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const autoplay = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(autoplay);
    };
  }, []);

  const nextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slideData.length);
  };

  const goToAuction = () => {
    navigate("/auctions");
  };

  return (
    <div className="slidercontainer" id="slidercontainer">
      <div className="left-col">
        {slideData.map((slide, index) => (
          <div
            className={`slide ${index === currentSlideIndex ? "current" : ""}`}
            key={index}
            style={{ backgroundImage: `url(${slide.src})` }}
          >
            <div
              className={`caption ${
                index === currentSlideIndex ? "current-caption" : ""
              }`}
            >
              <div className="caption-heading">
                <h1>{slide.title}</h1>
              </div>
              <div className="caption-subhead">
                <span>{slide.copy}</span>
              </div>
              <a href="" className="btn" onClick={goToAuction}>
                Shop More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
