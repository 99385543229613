import React from "react";
import "./Card04.css";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

function Card04() {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const goToLogin = () => {
    navigate("/auctions");
  };

  return (
    <div class="cardq">
      <p class="headingq">Start Shopping</p>
      <p class="paraq">
        If you already have already have an account you can start shopping
      </p>
      <div class="overlayq"></div>
      <div className="clogin">
        <button className="cardq-btnq" onClick={goToLogin}>
          Start Shopping{" "}
        </button>{" "}
      </div>
    </div>
  );
}

export default Card04;
