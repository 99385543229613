import React from "react";
import "./Card03.css";
function card02() {
  return (
    <div className="cdr">
      <div class="containerk">
        <div class="cardr">
          <div class="front-content">
            <p>Our Team</p>
          </div>
          <div class="content">
            <h3>Our Team</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi
              laboriosam at voluptas minus culpa deserunt delectus sapiente
              inventore pariatur
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default card02;
